.navbar-flat{
    background-color:#95afc0;
background:#cce7e9;
box-shadow:  -20px -20px 60px #433e3e,
             20px 20px 60px #ffffff;
}

.btn.button-momo{
    background-color: #82c9cb;
    color:#1622a0;
    border: none;
}
