.card.text-center{
    border-radius: 5px;
    
    border: none;
    box-shadow:  20px -20px 60px #bebebe,
             -20px 20px 80px #ffffff;
             background-color: #A9C9FF;
             background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);

    
}
input{
    
    padding: 12px 20px;
    /* margin: 20px 15px; */
   
    display: inline-block;
    border: 1.5px solid rgb(59, 57, 59);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 400px;
    
  }
