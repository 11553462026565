.card.infor_card{
    border-radius: 15px;

    border: none;
    box-shadow:  20px -20px 60px #bebebe,
             -20px 20px 80px #ffffff;

             margin-bottom: 30px;
             margin-left: 20px;
             margin-right: 20px;

             background-color: #A9C9FF;
             background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);
             
            
}

